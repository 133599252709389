.video-fullscreen {
  width: 100vw;
  height: 100.1vh;
  background-color: white;
  /* min-height: calc(99.5vw / 4.3 + 99.5vw); */
  object-fit: cover;
  object-position: center;
}
.video-wrapper {
  position: relative;
}
.video-error {
  width: 100vw;
  min-height: calc(99.5vw / 4.3 + 99.5vw);
  background-color: white;
  border-radius: 10px;

  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  position: relative;
}
