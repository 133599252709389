@import url("../App.css");

.card {
  padding: 16px 20px;

  border-radius: 40px;
  /* background-color: rgba(85, 88, 92, 0.8); */
  background-color: var(--color-quiz-prime);
  color: white;
}

.option {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.08s ease 0s;
  margin: 0px 5px 5px 0px;
}

.option:active {
  transform: scale3d(0.95, 0.95, 0.95);
}

.disabled {
  background-color: rgba(32, 36, 43, 1);
  color: rgba(255, 255, 255, 0.2);
  pointer-events: none;
}
