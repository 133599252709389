.btn_container {
  display: flex;
  justify-content: center;

  width: 80px;
  height: 80px;
  position: absolute;
  z-index: 2;

  justify-content: center;
  padding: 25px 0;

  border-radius: 50%;
}
