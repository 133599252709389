.btn_container {
  position: absolute;

  bottom: 30px;
  left: calc(50% - 40px);

  display: flex;
  justify-content: center;

  width: 80px;
  height: 80px;
  z-index: 2;

  padding: 25px 0;
  /* 
  background-color: #01d970;
  border-radius: 50%; */
}

.btn {
  /* position: absolute; */
}
