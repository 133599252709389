.task {
  margin-bottom: 20px;
  text-align: center;
  color: white;
  font-size: 45px;
  font-weight: bold;
  text-align: left;
  line-height: 90%;

  flex: 1;
}

.task_content_wrapper {
  margin-bottom: 30px;
}

.grid > * {
  width: 48%;
  min-height: 200px;
}

.question_wrapper {
  color: white;
  margin-bottom: 15px;
}

.options_wrapper {
  display: flex;
  flex-wrap: wrap;
}
