.userfeed_wrapper {
  /* height: 100vh; */
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  --webkit-scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
  --webkit-scroll-snap-stop: always;
  --webkit-overflow-scrolling: touch;
  /* will-change: scroll-position; */
  /* transform: translateZ(0); */

  background-color: #f3f3f3;
}
