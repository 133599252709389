.bar {
  display: flex;
  flex-wrap: wrap;
  background: rgb(255 255 255 / 23%);
  backdrop-filter: saturate(180%) blur(20px);
  border-radius: 30px;
  min-height: 45px;
  margin-bottom: 18px;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
}

.filler {
  padding: 13px 20px;
  border-radius: 40px;
  background-color: var(--color-quiz-prime);
  color: white;
}

.tick_wrapper {
  position: absolute;
  right: 0;
  width: 45px;
}
/* 
.tick {
  position: absolute;
  right: 0;
} */

.correct {
  width: 100%;
  background-color: rgba(0, 224, 115, 1);
  box-shadow: 0 0 44px 0px rgba(0, 220, 113, 0.5);
}

.incorrect {
  width: 100%;
  background-color: rgba(255, 77, 0, 1);
  box-shadow: 0 0 44px 0px rgba(255, 77, 0, 0.5);
}

.disabled {
  background-color: rgba(32, 36, 43, 1);
  color: rgba(255, 255, 255, 0.2);
  pointer-events: none;
}
