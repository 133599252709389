.slide_container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}

.next_btn_wrapper {
  display: inline-flex;
  justify-content: flex-end;
}
