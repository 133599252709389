@import url("../App.css");

.card {
  padding: 20px;

  border-radius: 40px;
  /* background-color: rgba(85, 88, 92, 0.8); */
  background-color: var(--color-quiz-prime);
  color: white;
}

.option {
  position: relative;

  margin-bottom: 9px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.08s ease 0s;
}

.option:active {
  transform: scale3d(0.95, 0.95, 0.95);
}

.tick {
  position: absolute;
  right: 0;
  width: 60px;
}

.correct {
  background-color: rgba(0, 224, 115, 1);
  box-shadow: 0 0 44px 0px rgba(0, 220, 113, 0.5);
}

.incorrect {
  background-color: rgba(255, 77, 0, 1);
  box-shadow: 0 0 44px 0px rgba(255, 77, 0, 0.5);
}

.disabled {
  background-color: rgba(32, 36, 43, 1);
  color: rgba(255, 255, 255, 0.2);
  pointer-events: none;
}
