.container {
  /* background: rgba(0, 0, 0, 0.8); */
}

.chart_container {
  margin: 0 -25px;
}

.percentage_title {
  color: white;
  font-size: 60px;
  font-weight: bold;

  margin: 0 0 0 0;
}

.copy {
  color: white;
}
