.slide_container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.task {
  margin-bottom: 20px;
  text-align: center;
  color: white;
  font-size: 45px;
  font-weight: bold;
  text-align: left;
  line-height: 90%;

  flex: 1;
}
