.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
}

.title {
  font-weight: bold;
  font-size: 22px;
  color: rgb(255, 255, 255);
  flex: 1;
}

.task_container {
  flex: 8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
