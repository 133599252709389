@import url("../App.css");

.task {
  margin-bottom: 20px;
  text-align: center;
  color: white;
  font-size: 45px;
  font-weight: bold;
  text-align: left;
  line-height: 90%;

  flex: 1;
}

.task_content_wrapper {
  border-top: 1px solid #4c4c4c;
  padding-top: 21px;
  margin-bottom: 30px;
}

.grid > * {
  width: 49%;
  min-height: 60px;
}

.question_wrapper {
  color: white;
  margin-bottom: 20px;
}

.options_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}
