.status_bar {
  position: absolute;
  color: white;
  z-index: 2;
  background: #48c600;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 22px;
  line-height: 96%;

  right: 19px;
  top: 14px;
  z-index: 2;
}

.points {
  position: absolute;
  top: 60px;
  right: 40px;
  /* color: #48c600; */
  color: white;
  font-weight: bold;
  transform: scale(0) translateY(0px);
  z-index: 1;
}

.points {
}

@keyframes pop {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1.4);
  }
  30% {
    transform: scale(1);
  }
  80% {
    /* transform: translateY(-10px); */
    /* transform: translateY(-40px); */
  }
  100% {
    transform: translateY(-40px);
  }
}

.animate .points {
  animation: pop 1s ease-in 1;
}

.animate .pulser::after {
  animation: pulse 1s cubic-bezier(0.9, 0.7, 0.5, 0.9) 1;
  animation-delay: 0.4s;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    transform: scale(1.4);
    opacity: 0.4;
  }
}

.pulser::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #48c600;
  border-radius: 22px;
  z-index: -1;
}
