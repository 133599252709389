@import url("../App.css");

.tick_container {
  width: 100%;
  height: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;

  background-color: white;
  border-radius: 50%;
  color: green;

  padding: 50% 0;
}

.tick_container > img {
  width: 25px;
  margin-top: 1px;
}
